
import ApplicationCaseStatisticChild from './ApplicationCaseStatisticChild.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { deepClone } from '@/utils/function_utils';

@Component({
  components: { ApplicationCaseStatisticChild },
  name: 'applicationCaseStatistic'
})
export default class extends Vue {
  @Prop({ default: [] }) private datas!: any;

  get getDatas() {
    if (this.datas && this.datas.length) {
      const titleData = {
        name: this.$t('index.applyStatus'),
        studentNum: this.$t('index.studentCount'),
        itemNum: this.$t('index.applyCount'),
      }
      const data = deepClone(this.datas);
      data.splice(0, 0, titleData);
      return data;
    }
    return []
  }
}
