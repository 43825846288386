
import * as echarts from 'echarts';
import ResizeMixin from '@/components/Charts/mixins/resize';
import { deepClone, getCurYear } from '@/utils/function_utils';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';

@Component({
  name: 'applicationCountBar',
})
export default class extends Mixins(ResizeMixin) {
  @Prop({ default: 'bar-chart' }) public className!: string;
  @Prop({ default: '100%' }) public width!: string;
  @Prop({ default: '378px' }) public height!: string;
  @Prop({ default: [] }) public data!: any;
  @Prop({ default: 0 }) public year!: number;
  @Prop({ default: '' }) public allApplyStudentCountTotal!: string | number;
  @Prop({ default: '' }) public allYearEnrolledStudentTotal!: string | number;
  @Prop({ default: ''}) public thisYearStudentSum!: string | number;
  @Prop({ default: ''}) public thisYearApplyNum!: string | number;
  @Prop() public sucFlag!: string;

  get language() {
    return AppModule.language;
  }

  public chart: any = null;
  public xAxis: any = [];
  public legendDataType0: any[] = [];
  public legendDataType1: any[] = [];
  public valueConfigs0: any[] = []
  public valueConfigs1: any[] = []

  // @Watch('data', {deep: true})
  @Watch('language')
  @Watch('year')
  public watchNum(val: any) {
    console.log(this.data, val);
    // if (typeof val === 'string') {
    this.configData();
    // }
    if (this.year === 0) {
      const seriesData = this.getSeriesData(0);
      this.setEchartsOptions(this.legendDataType0, seriesData)
    } else {
      const seriesData = this.getSeriesData(1);
      this.setEchartsOptions(this.legendDataType1, seriesData)
    }
  }

  public mounted() {
    this.$nextTick(() => {
      this.configData();
      this.initChart();
    })
  }

  public beforeDestroy() {
    if (!this.chart) {
      return;
    }
    // 销毁实例，实例销毁后无法再被使用
    this.chart.dispose();
    this.chart = null;
  }

  public configData() {
    this.xAxis = [
      this.$t('common.jan'),
      this.$t('common.feb'),
      this.$t('common.mar'),
      this.$t('common.apr'),
      this.$t('common.may'),
      this.$t('common.jun'),
      this.$t('common.jul'),
      this.$t('common.aug'),
      this.$t('common.sep'),
      this.$t('common.oct'),
      this.$t('common.nov'),
      this.$t('common.dec'),
    ]
    let curYear = getCurYear()
    if (this.year !== 0) {
      curYear = this.year
    }
    this.legendDataType0 = [
      this.$t('index.nowStudentCount', {year: curYear}),
      this.$t('index.nowReadingCount', {year: curYear, text: this.sucFlag === '1' ? this.$t('index.osCount') : this.$t('index.enrolledSuccess')}),
      this.$t('index.allStudentCount'),
      this.$t('index.allReadingCount', {text: this.sucFlag === '1' ? this.$t('index.osCount') : this.$t('index.enrolledSuccess')}),
    ];
    this.legendDataType1 = [
      this.$t('index.nowStudentCount', {year: curYear}),
      this.$t('index.nowReadingCount', {year: curYear, text: this.sucFlag === '1' ? this.$t('index.osCount') : this.$t('index.enrolledSuccess')})
    ]
    // xAxisIndex 值相同的组共X轴，
    // (xAxisIndex为某x轴的编号，当前定义了两个X轴，编号 0,1。轴编号相同的数据为同轴数据，依次按值定义顺序排列到x轴上)
    // 由于没有定义x轴的偏移量，所以定义的两条x轴是重合的
    this.valueConfigs0 = [
      {color: '#c0c0c0', keyName: 'allApplyStudentCount', name: this.$t('index.allStudentCount'), xAxisIndex: 1},
      {color: '#808080', keyName: 'allEnrolledStudentCount', name: this.$t('index.allReadingCount', {text: this.sucFlag === '1' ? this.$t('index.osCount') : this.$t('index.enrolledSuccess')}), xAxisIndex: 1},
      {
        color: '#4DCCB6',
        keyName: 'thisYearApplyStudentCount',
        name: this.$t('index.nowStudentCount', {year: curYear}), xAxisIndex: 0
      },
      {
        color: '#F6A14D',
        keyName: 'thisYearEnrolledStudentCount',
        name: this.$t('index.nowReadingCount', {year: curYear, text: this.sucFlag === '1' ? this.$t('index.osCount') : this.$t('index.enrolledSuccess')}), xAxisIndex: 0
      },
    ]
    this.valueConfigs1 = [
      {
        color: '#4DCCB6',
        keyName: 'thisYearApplyStudentCount',
        name: this.$t('index.nowStudentCount', {year: curYear}), xAxisIndex: 0
      },
      {
        color: '#F6A14D',
        keyName: 'thisYearEnrolledStudentCount',
        name: this.$t('index.nowReadingCount', {year: curYear, text: this.sucFlag === '1' ? this.$t('index.osCount') : this.$t('index.enrolledSuccess')}), xAxisIndex: 0
      },
    ]
  }

  public getSeriesData(type: number) {
    const series: any = [];
    const serieInitObj = {
      name: '',
      type: 'bar',
      // show: false,
      barWidth: '30%',
      barGap: '0%', // 不同系列的柱间距离
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      xAxisIndex: 0,
      itemStyle: {
        normal: {
          color: ''
        }
      },
    }

    if (type === 0) {
      this.valueConfigs0.forEach((item) => {
        const initObj = deepClone(serieInitObj)
        if (item.color) {
          const serieObj = this.data[item.keyName];
          initObj.itemStyle.normal.color = item.color;
          initObj.name = item.name;
          initObj.data = serieObj;
        }
        initObj.xAxisIndex = item.xAxisIndex;
        series.push(initObj);
      });
    } else {
      this.valueConfigs1.forEach((item) => {
        const initObj = deepClone(serieInitObj)
        if (item.color) {
          const serieObj = this.data[item.keyName];
          initObj.itemStyle.normal.color = item.color;
          initObj.name = item.name;
          initObj.data = serieObj;
        }
        initObj.xAxisIndex = item.xAxisIndex;
        series.push(initObj);
      });
    }
    return series;
  }

  public initChart() {
    const barChartDom = this.$refs.barChart;
    this.chart = echarts.init(barChartDom as HTMLDivElement);
    if (this.year === 0 || !this.year) {
      const seriesData = this.getSeriesData(0);
      this.setEchartsOptions(this.legendDataType0, seriesData)
    } else {
      const seriesData = this.getSeriesData(1);
      this.setEchartsOptions(this.legendDataType1, seriesData)
    }
  }

  public setEchartsOptions(legendData: any, seriesData: any) {
    const that = this;
    this.chart.setOption({
      tooltip: {
        trigger: 'axis',
        formatter: (params: any, ticket: any, callback: any) => {
          const a = params
            .filter((_: any) => _.seriesName)
            .map((_: any) => `<span style="display: inline-block;background-color: ${_.color};width: 10px;height:10px;border-radius: 50%;margin-right:6px"></span>${_.seriesName}：${_.value}`)
            .join('</span><br/><span>');
          return `<span>${a}</span>`;
        },
        backgroundColor: 'rgba(51,51,51,0.8)',
        borderColor: 'rgba(51,51,51,0.8)',
        textStyle: {
          color: '#fff'
        },
        axisPointer: {
          type: 'shadow',
          animation: true,
          shadowStyle: {
            color: {
              width: '100%',
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [
                { offset: 0, color: 'RGBA(0,0,0,0)' },
                { offset: 0.15, color: 'RGBA(0,0,0,0)' },
                { offset: 0.15, color: 'RGBA(100,100,100,0.3)' },
                { offset: 0.85, color: 'RGBA(100,100,100,0.3)' },
                { offset: 0.85, color: 'RGBA(0,0,0,0)' },
                { offset: 1, color: 'RGBA(0,0,0,0)' }
              ],
              global: false
            },
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0
          }
        }
      },
      legend: {
        // silent: true,
        // 图例的数据数组。数组项通常为一个字符串，每一项代表一个系列的 name
        // 如果 data 没有被指定，会自动从当前系列中获取。多数系列会取自 series.name
        data: legendData,
        formatter: (name: any) => {
          if (['全部学生数', '全部学生數', 'Total students'].includes(name)) {
            return `${name}: ${that.allApplyStudentCountTotal}`;
          } else if (['年学生数', '年学生數', 'Number of students in'].some((item) => name.includes(item))) {
            return `${name}: ${that.thisYearStudentSum ? that.thisYearStudentSum : 0}`;
          } else if (['全部成功入读数', '全部成功入讀數', '全部OS数', '全部OS數', 'Total Successful entry reading', 'Total Number of OS'].includes(name)) {
            return `${name}: ${that.allYearEnrolledStudentTotal}`;
          } else if (['年成功入读数', '年成功入讀數', '年OS数', '年OS數', 'Number of Successful entry reading in', 'Number of OS in'].some((item) => name.includes(item))) {
            return `${name}: ${that.thisYearApplyNum ? that.thisYearApplyNum : 0}`;
          }
          return name;
        }
      },
      grid: {
        bottom: '30'
      },
      yAxis: {
        show: true,
        type: 'value',
      },
      xAxis: [
        {
          type: 'category',
          data: this.xAxis
        },
        {
          show: false,
          type: 'category',
          data: ['', '', '', '', '', '', '', '', '', '', '', '']
        },
        // {
        //   show: false,
        //   type: 'category',
        //   data: ['', '', '', '', '', '', '', '', '', '', '', '']
        // },
        // {
        //   show: false,
        //   type: 'category',
        //   data: ['', '', '', '', '', '', '', '', '', '', '', '']
        // }
      ],
      series: seriesData
    }, {replaceMerge: ['series']});
    this.chart.on('click', (params: any) => {
      const month = params.dataIndex;
      const year = params.seriesName.replace(/[^\d]/g, "");
      if (year) {
        const endTime = `${year}-${month + 1}-${new Date(year, (month + 1), 0).getDate()}`;
        const startTime = `${year}-${(month + 1)}-1`
        this.$router.push({ name: 'studentInfoManagement', query: { startTime: startTime.toString(), endTime: endTime.toString() } })
      } else {
        this.$router.push({ name: 'studentInfoManagement' });
      }
    });
  }
}
