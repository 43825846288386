
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';

@Component({
  name: 'applicationCaseStatisticComp'
})
export default class extends Vue {
  @Prop() private data!: any;

  get year() {
    return UserModule.pageIndexSelectYear;
  }

  public handleClick(data: any, failReason: string | undefined) {
    const year = this.year;
    const stepKey = data.stepKey;
    failReason = failReason ? failReason : '';
    this.$router.push({
      name: 'studentInfoManagement',
      query: { stepKey, year, failReason },
      params: { type: 'index' }
    })
  }
}
