
import ApplicationCountBar from './components/ApplicationCountBar.vue';
import CountryDistributionPie from './components/CountryDistributionPie.vue';
import StudentApplyNewestList from './components/StudentApplyNewestList.vue';
import ApplicationCaseStatistic from './components/ApplicationCaseStatistic.vue';
import { Component, Vue } from 'vue-property-decorator';
import { getSummaryDatasApi, getSucFlagApi } from '@/api/student-management';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';

@Component({
  name: 'index',
  components: {
    ApplicationCountBar,
    CountryDistributionPie,
    StudentApplyNewestList,
    ApplicationCaseStatistic,
  }
})
export default class extends Vue {

  get showChart() {
    const applyStudentCount = this.applicationCountBarData.allApplyStudentCount;
    return applyStudentCount && applyStudentCount.length;
  }

  get showPie() {
    const countryStudentCount = this.countryStudentCount;
    return countryStudentCount && countryStudentCount.length;
  }

  get language() {
    return AppModule.language;
  }

  get showAppStatusPermission() {
    return UserModule.isShowAppStatusPermission;
  }

  public year = '0';
  public yearVo = 0;
  public sucFlag = '';
  public yearOptions: any = [];
  public applicationCountBarData: any = {
    allApplyStudentCount: [],
    allEnrolledStudentCount: [],
    thisYearApplyStudentCount: [],
    thisYearEnrolledStudentCount: [],
  };
  public countryStudentCount: any = [];
  public studentCountRecords: any = [];
  public studentOfferItemStatisticalStatus: any = [];
  public studentTotalSum: number | string = ''; // 学生数
  public studentTotalNum: number | string = ''; // 申请数
  public thisYearApplyStudentCountTotal: number | string = ''; // 今年学生数
  public thisYearEnrolledStudentTotal: number | string = ''; // 今年申请数
  // public thisYearApplyStudentCount: number | string = '';
  // public thisYearEnrolledStudentCount: number | string = '';
  public thisYearApplyStudentCountPercent: number | string = '';
  public allYearEnrolledStudentTotal: number | string = '';
  public allApplyStudentCountTotal: number | string = '';

  public enrolledSuccessProportion: number | string = '';

  public mounted() {
    this.year = UserModule.pageIndexSelectYear ? UserModule.pageIndexSelectYear : this.year;
    this.getSummaryDatas();
    this.getSucFlag();
  }

  public async getSummaryDatas() {
    const { data } = await getSummaryDatasApi(this.year);
    this.studentTotalSum = data.data.studentTotalSum;
    this.studentTotalNum = data.data.studentTotalNum;
    this.allApplyStudentCountTotal = data.data.allApplyStudentCountTotal;
    this.allYearEnrolledStudentTotal = data.data.allYearEnrolledStudentTotal;
    this.thisYearEnrolledStudentTotal = data.data.thisYearEnrolledStudentTotal;
    this.thisYearApplyStudentCountTotal = data.data.thisYearApplyStudentCountTotal;
    this.enrolledSuccessProportion = data.data.enrolledSuccessProportion;
    this.countryStudentCount = data.data.countryStudentCount;
    this.studentCountRecords = data.data.studentCountRecords;
    this.yearVo = data.data.yearVo
    this.applicationCountBarData = Object.assign({},
      { allApplyStudentCount: data.data.allApplyStudentCount },
      { allEnrolledStudentCount: data.data.allEnrolledStudentCount},
      { thisYearApplyStudentCount: data.data.thisYearApplyStudentCount },
      { thisYearEnrolledStudentCount: data.data.thisYearEnrolledStudentCount}
    );
    this.studentOfferItemStatisticalStatus = data.data.studentOfferItemStatisticalStatusDtos;
    this.yearOptions = data.data.allYears;
  }

  public async getSucFlag() {
    const { data } = await getSucFlagApi();
    this.sucFlag = data.datas[0].sucFlag;
  }

  public handleChangeYear() {
    this.getSummaryDatas();
    UserModule.SET_PAGE_INDEX_SELECT_YEAR(this.year);
  }
}
