
import StudentApplyNewsComp from './StudentApplyNewsComp.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'studentApplyNewestList',
  components: {
    StudentApplyNewsComp
  }
})
export default class extends Vue {
  @Prop() public sucFlag!: string;
  @Prop({ default: '' }) private studentTotalSum!: string | number;
  @Prop({ default: '' }) private studentTotalNum!: string | number;
  @Prop({ default: '' }) private enrolledSuccessProportion!: string | number;
  @Prop({ default: [] }) private datas!: any;
  @Prop({ default: '0' }) private year!: string;

  get showCountRecord() {
    const datas = this.datas;
    if (datas && datas.length) {
      return true;
    }
    return false;
  }

  get successProportion() {
    if (this.enrolledSuccessProportion) {
      return this.enrolledSuccessProportion + '%';
    }
    return '0%';
  }

  get totalNum() {
    return this.studentTotalNum || 0;
  }

  get totalSum() {
    return this.studentTotalSum || 0;
  }

  public linkToStudentManagement() {
    this.$router.push({ name: 'studentInfoManagement', query: { year: this.year } });
  }
}
