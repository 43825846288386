
import * as echarts from 'echarts';
import ResizeMixin from '@/components/Charts/mixins/resize';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';

@Component({
  name: 'countryDistributionPie'
})
export default class extends Mixins(ResizeMixin) {
  @Prop({ default: 'pie-chart' }) public className!: string;
  @Prop({ default: '100%' }) public width!: string;
  @Prop({ default: '378px' }) public height!: string;
  @Prop({ default: [] }) public datas!: any;

  public chart: any = null;

  get language() {
    return AppModule.language;
  }


  @Watch('datas', {deep: true})
  public watchNum() {
    const seriesData = this.getSeriesData();
    this.setEchartsOptions(seriesData)
  }


  public mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  }

  public beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  public getSeriesData() {
    const data: any = [];
    this.datas.forEach((item: any) => {
      const language = this.language;
      let name = '';
      if (language === 'en') {
        name = item.countryName;
      } else {
        name = item.countryNameChn;
      }
      const obj = {
        name,
        value: item.studentNum,
      }
      data.push(obj);
    });
    return data;
  }

  public initChart() {
    const pieChartDom = this.$refs.pieChart;
    this.chart = echarts.init(pieChartDom as HTMLDivElement);
    const seriesData = this.getSeriesData();
    this.setEchartsOptions(seriesData);
  }

  public setEchartsOptions(seriesData: any) {
    this.chart.setOption({
    tooltip: {
      trigger: 'item',
        formatter: (params: any) => {
          return `<span style="display: inline-block;background-color: ${params.color};width: 10px;height:10px;border-radius: 50%;margin-right:6px"></span><span style="color: ${params.color};">${params.name}：${params.value} (${params.percent}%)</span>`;
        },
      },
      legend: {
        orient: 'vertical',
        bottom: '5%',
        left: 'left'
      },
      grid: {
        bottom: '10'
      },
      series: [
        {
          name: '',
          type: 'pie',
          radius: ['45%', '80%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: true,
            fontSize: 13,
            formatter: (params: any) => {
              return `${params.percent}%`
            },
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '12',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: true,
            minTurnAngle: 45,
          },
          data: seriesData
        }
      ]
    })
  }
}
